<style lang="less" scoped>
.list {
  //background-color: #333333;
  font-size: 0.9rem;
  color: #ffffff;
  .list-item {
    padding: 10px;
    border-bottom: solid 0.1px rgba(235, 237, 240, 0.22);
    height: 25px;
    line-height: 25px;
    position: relative;
    .van-icon{
      position: absolute;
      display: inline-block;
      top: 35%;
      right: 3%;
      color: rgba(255,255,255,0.5);
    }
  }
}
</style>

<template>
  <div style='position: relative;min-height: 835px;'>
    <div class="list">
      <div @click="handlerGoRoute('/setting/fund_trans')" class="list-item">资金划转<van-icon name="arrow" /></div>
      <div @click="handlerGoRoute('/setting/fund_unio')" class="list-item">资金归集<van-icon name="arrow" /></div>
      <div class="list-item">交易记录</div>
      <div class="list-item">免责声明<van-icon name="arrow" /></div>
      <div class="list-item"></div>
      <div class="list-item"></div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>
</template>
<script>
import VersionNum from "../com/version_num";
export default {
  name: "setting",
  components:{VersionNum},
  methods: {
    handlerGoRoute(e) {
      this.$router.push(e);
    },
  },
};
</script>
